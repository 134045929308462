import SwipeListener from 'swipe-listener';

document.addEventListener('DOMContentLoaded', () => {
  getItemsMaxHeightAndSetItForTheRest();

  const touchableElements = document.querySelector('.translators-carousel-items');
  const touchableElementsArray = Array.from(document.getElementsByClassName('translators-carousel-input'));
  const elementsArrayLength = touchableElementsArray.length;

  SwipeListener(touchableElements);
  touchableElements.addEventListener('swipe', function (e) {
    let directions = e.detail.directions;

    if (directions.left || directions.right) {
      let checkedElement = touchableElementsArray.findIndex(element => element.checked == true);
      touchableElementsArray[checkedElement].checked = false;

      if (directions.left) {
        if (checkedElement == elementsArrayLength - 1) {
          touchableElementsArray[0].checked = true;
        } else {
          touchableElementsArray[checkedElement + 1].checked = true;
        }
      }

      if (directions.right) {
        if (checkedElement == 0) {
          touchableElementsArray[elementsArrayLength - 1].checked = true;
        } else {
          touchableElementsArray[checkedElement - 1].checked = true;
        }
      }
    }
  });
});

window.addEventListener('resize', () => {
  getItemsMaxHeightAndSetItForTheRest();
});

const getItemsMaxHeightAndSetItForTheRest = () => {
  const items = document.querySelectorAll('.translators-carousel-item');
  items.forEach(e => (e.style.height = null));
  document.querySelector('.translators-carousel-items').style.height = null;
  let itemsHeights = Array.from(items).map(item => item.offsetHeight);
  let itemsMaxHeight = Math.max(...itemsHeights);

  items.forEach(e => (e.style.height = itemsMaxHeight + 'px'));
  document.querySelector('.translators-carousel-items').style.height = itemsMaxHeight + 'px';
};
