export default function scrollToElement(buttonId, elementId) {
  const button = document.getElementById(buttonId);
  if (!button) return;

  button.addEventListener('click', e => {
    e.preventDefault();

    const header = document.getElementById('header');
    const headerHeight = header ? header.offsetHeight : 0;
    const element = document.getElementById(elementId);
    if (!element) return;

    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: elementPosition - headerHeight,
      behavior: 'smooth',
    });
  });
}
