import Splide from '@splidejs/splide';

if ($('#splide-media').length > 0) {
  new Splide('#splide-media', {
    type: 'loop',
    perPage: 4,
    perMove: 1,
    focus: 0,
    dots: false,
    rewind: false,
    pagination: false,
    autoplay: true,
    autoHeight: true,
    lazyLoad: 'sequential',
    easing: 'linear',
    interval: 5000,
    height: '100%',
    breakpoints: {
      699: {
        perPage: 1,
      },
      992: {
        perPage: 3,
      },
    },
  }).mount();
}
if ($('#splide-awards').length > 0) {
  new Splide('#splide-awards', {
    type: 'loop',
    perPage: 5,
    perMove: 1,
    focus: 0,
    rewind: true,
    pagination: false,
    lazyLoad: 'sequential',
    easing: 'linear',
    autoplay: true,
    interval: 5000,
    breakpoints: {
      699: {
        perPage: 1,
      },
      992: {
        perPage: 3,
      },
    },
  }).mount();
}
