import anime from 'animejs';
import scrollToElement from '../common/scrollToElement';

document.addEventListener('DOMContentLoaded', function () {
  const numberWrappers = document.querySelectorAll('.vasco-numbers-single-wrapper');

  const animateNumber = wrapper => {
    const num = wrapper.querySelector('.number');
    const finalValue = parseInt(num.textContent.trim(), 10);
    anime({
      targets: num,
      innerHTML: [0, finalValue],
      round: 1,
      easing: 'easeOutExpo',
      duration: 2000,
    });
  };

  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          animateNumber(entry.target);
          observer.unobserve(entry.target);
        }
      });
    },
    {
      threshold: 0.5,
    },
  );

  numberWrappers.forEach(wrapper => {
    observer.observe(wrapper);
  });

  scrollToElement('awards-scroll', 'awards-section');
});
